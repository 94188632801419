exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-buy-checkout-js": () => import("./../../../src/pages/buy/checkout.js" /* webpackChunkName: "component---src-pages-buy-checkout-js" */),
  "component---src-pages-buy-index-js": () => import("./../../../src/pages/buy/index.js" /* webpackChunkName: "component---src-pages-buy-index-js" */),
  "component---src-pages-buy-refund-js": () => import("./../../../src/pages/buy/refund.js" /* webpackChunkName: "component---src-pages-buy-refund-js" */),
  "component---src-pages-buy-thankyou-js": () => import("./../../../src/pages/buy/thankyou.js" /* webpackChunkName: "component---src-pages-buy-thankyou-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-beta-js": () => import("./../../../src/pages/download/beta.js" /* webpackChunkName: "component---src-pages-download-beta-js" */),
  "component---src-pages-download-free-js": () => import("./../../../src/pages/download/free.js" /* webpackChunkName: "component---src-pages-download-free-js" */),
  "component---src-pages-download-index-js": () => import("./../../../src/pages/download/index.js" /* webpackChunkName: "component---src-pages-download-index-js" */),
  "component---src-pages-download-kr-js": () => import("./../../../src/pages/download/kr.js" /* webpackChunkName: "component---src-pages-download-kr-js" */),
  "component---src-pages-download-portable-js": () => import("./../../../src/pages/download/portable.js" /* webpackChunkName: "component---src-pages-download-portable-js" */),
  "component---src-pages-download-pro-js": () => import("./../../../src/pages/download/pro.js" /* webpackChunkName: "component---src-pages-download-pro-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-help-heic-js": () => import("./../../../src/pages/help/heic.js" /* webpackChunkName: "component---src-pages-help-heic-js" */),
  "component---src-pages-help-hotkeys-js": () => import("./../../../src/pages/help/hotkeys.js" /* webpackChunkName: "component---src-pages-help-hotkeys-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-help-manual-wip-asdf-js": () => import("./../../../src/pages/help/manual_wip_asdf.js" /* webpackChunkName: "component---src-pages-help-manual-wip-asdf-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-admin-js": () => import("./../../../src/pages/license/admin.js" /* webpackChunkName: "component---src-pages-license-admin-js" */),
  "component---src-pages-license-forgot-confirmation-js": () => import("./../../../src/pages/license/forgot/confirmation.js" /* webpackChunkName: "component---src-pages-license-forgot-confirmation-js" */),
  "component---src-pages-license-forgot-index-js": () => import("./../../../src/pages/license/forgot/index.js" /* webpackChunkName: "component---src-pages-license-forgot-index-js" */),
  "component---src-pages-license-index-js": () => import("./../../../src/pages/license/index.js" /* webpackChunkName: "component---src-pages-license-index-js" */),
  "component---src-pages-license-notfound-js": () => import("./../../../src/pages/license/notfound.js" /* webpackChunkName: "component---src-pages-license-notfound-js" */),
  "component---src-pages-license-offline-js": () => import("./../../../src/pages/license/offline.js" /* webpackChunkName: "component---src-pages-license-offline-js" */),
  "component---src-pages-manual-wip-asdf-js": () => import("./../../../src/pages/manual_wip_asdf.js" /* webpackChunkName: "component---src-pages-manual-wip-asdf-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-promo-cobi-js": () => import("./../../../src/pages/promo/cobi.js" /* webpackChunkName: "component---src-pages-promo-cobi-js" */),
  "component---src-pages-readme-js": () => import("./../../../src/pages/readme.js" /* webpackChunkName: "component---src-pages-readme-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-uninstall-js": () => import("./../../../src/pages/uninstall.js" /* webpackChunkName: "component---src-pages-uninstall-js" */),
  "component---src-pages-update-js": () => import("./../../../src/pages/update.js" /* webpackChunkName: "component---src-pages-update-js" */)
}

